<template>
  <div>

    <b-row>
      <b-col class="text-white" style="margin-top: 75px; position: absolute;">
        <div style="width: 100%; max-width: 450px; margin-left: auto; margin-right: auto; z-index: 50; background-color: rgba(0, 0, 0, 0.7); padding: 15px 30px 15px 30px; border-radius: 5px; border: 1px solid #636363; -webkit-box-shadow: 0px 0px 25px 15px rgba(0,0,0,0.7); box-shadow: 0px 0px 25px 15px rgba(0,0,0,0.7);">
          <h1 style="font-size: 25px;" class="text-center">LOGIN</h1>
          <hr style="background-color: white; margin-left: 60px; margin-right: 60px; opacity: 0.7;">
          <p v-show="loginFail" class="text-center" style="color: rgb(255, 255, 128);">Login failed. Please try again.</p>
          <b-form @submit.prevent="login">
            <b-form-group
              id="email-group"
              label="Email address:"
              label-for="email"
            >
              <b-form-input
                id="input-1"
                v-model="email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group id="password-group" label="Password:" label-for="password">
              <b-form-input
                type="password"
                id="password"
                v-model="password"
                required
              ></b-form-input>
            </b-form-group>

            <b-button v-show="!loginProcessing" type="submit" class="btn-dash-submit float-right">Login</b-button>
            <b-button disabled v-show="loginProcessing" type="submit" class="disabled btn-dash-submit float-right"><b-icon icon="arrow-clockwise" animation="spin" style="height: 18px; width: 18px; margin-right: 2px;" font-scale="4"></b-icon> Login</b-button>

          </b-form>
          <p class="password-help small text-center" style="margin-top: 70px;">Forgot your password?
            <br>Please
            <a class="password-help-link" href="#" @click.prevent="openSupport()">
              contact support
            </a>
            , and we'll get you back in business.
          </p>
        </div>
      </b-col>
      
    </b-row>

    <b-row id="login-cover-img" class=""></b-row>

  </div>
</template>

<script>

export default {
  name: 'login',

  metaInfo: {
    title: 'Login'
  },

  components: {

  },

  data() {
    return {
      loginProcessing: false,
      loginFail: false,
      email: null,
      password: null,
      validation: {
        email: null,
        password: null,
      }
    }    
  },

  methods: {

    closeEverything() {
      this.$store.commit("closeEverything");
    },

    login() {
        this.loginProcessing = true;

        this.$store.dispatch('retrieveToken', {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$router.push('/dashboard');
        })
        .catch(() => {
          this.loginFail = true;
          this.loginProcessing = false;
        });

      },

    validateForm(){
      this.validation.email     = this.email.length > 6 ? true : false;
      this.validation.message   = this.password.length > 6 ? true : false;

      return Object.values(this.validation).every(Boolean);
    },

    openSupport() {
      this.$store.commit("toggleModal");
      this.$store.commit("toggleSupport");
    },

  },

  computed: {

  }
}
</script>

<style scoped>
  #login-cover-img {
    position: abolute;
      z-index: 5;
      background-image: url('../assets/login_bg.jpg');
      background-repeat: no-repeat;
      background-position: left;
      background-size: cover;
      border-bottom: 3px solid #69a1cf;
      height: 750px;
  }

  .password-help {
    color: #b6b6b6;
  }

  .password-help-link {
    text-decoration: underline;
    color: #b6b6b6;
  }

  .password-help-link:hover {
    transition: all 0.5;
    color: white;
  }

  .btn-dash-submit {
    background-color: rgb(23, 92, 142);
    border: none;
  }

</style>